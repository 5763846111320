"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.crossmintStatusButtonService = void 0;
var types_1 = require("../models/types");
function crossmintStatusButtonService(_a) {
    var onClick = _a.onClick;
    var getButtonText = function (status) {
        switch (status) {
            case types_1.onboardingRequestStatusResponse.INVALID:
                return "Invalid clientId";
            case types_1.onboardingRequestStatusResponse.WAITING_SUBMISSION:
                return "Click here to setup Crossmint";
            case types_1.onboardingRequestStatusResponse.PENDING:
                return "Your application is under review";
            case types_1.onboardingRequestStatusResponse.ACCEPTED:
                return "You're good to go!";
            case types_1.onboardingRequestStatusResponse.REJECTED:
                return "Your application was rejected";
        }
    };
    var isButtonDisabled = function (status) {
        return status !== types_1.onboardingRequestStatusResponse.WAITING_SUBMISSION;
    };
    var handleClick = function (event, status, goToOnboarding) {
        if (onClick)
            onClick(event);
        if (status === types_1.onboardingRequestStatusResponse.WAITING_SUBMISSION) {
            goToOnboarding();
        }
    };
    return {
        getButtonText: getButtonText,
        isButtonDisabled: isButtonDisabled,
        handleClick: handleClick,
    };
}
exports.crossmintStatusButtonService = crossmintStatusButtonService;
