/** @format */

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "../../redux/blockchain/blockchainActions";
import { fetchData } from "../../redux/data/dataActions";
import { notification, Form, Input, Button } from "antd";
import { CrossmintPayButton } from "../../component/CustomizeBtn";
import swal from "sweetalert";
import { Link } from "react-router-dom";
import PdfWhitepaper from "../../assests/docs/Minetopia_Whitepaper.pdf";
import utilmodal from "../../component/UtilModal";
import rp from "request-promise";

function Home() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [toggleMenu, setToggleMenu] = useState(false);
  const [mintAmount, setMintAmount] = useState(1);
  const [isApprove, setApprove] = useState(false);
  const [isMint, setMintFlag] = useState(false);
  const [token, setToken] = useState(0);
  const [email, setEmail] = useState("");
  const [isVisible, setVisibleBlock] = useState(false);
  const [content, setContent] = useState("");
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    FINNEY_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  const validateMessages = {
    required: "user email is required!",
    types: {
      email: "email is not a valid email!",
    },
  };

  const LEFT_DAYS_IN_MS =
    new Date("2022-09-01T12:00:00-00:00").getTime() - new Date().getTime();
  const NOW_IN_MS = new Date().getTime();
  const dateTimeAfterThreeDays = NOW_IN_MS + LEFT_DAYS_IN_MS;

  const claimNFTs = async () => {
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit * mintAmount);
    setClaimingNft(true);

    let tokenPrice = await blockchain.smartContract.methods.getPrice().call();
    let balance = await blockchain.usdcContract.methods
      .balanceOf(blockchain.account)
      .call();

    if (balance >= tokenPrice * mintAmount) {
      blockchain.smartContract.methods
        .userMint(blockchain.account, mintAmount)
        .send({
          to: CONFIG.CONTRACT_ADDRESS,
          from: blockchain.account,
        })
        .once("error", (err) => {
          utilmodal(
            "error",
            "Sorry, something went wrong please try again later.",
            401
          );
          setClaimingNft(false);
        })
        .then((receipt) => {
          utilmodal("WOW", the`${CONFIG.NFT_NAME} is yours!`, 200);
          setClaimingNft(false);
          dispatch(fetchData(blockchain.account));
        });
    } else {
      utilmodal("warning", "Sorry, Not enough USDC.", 400);
      setClaimingNft(false);
    }
  };

  const approve = () => {
    console.log(CONFIG.APPROVE_AMOUNT);
    blockchain.usdcContract.methods
      .approve(CONFIG.CONTRACT_ADDRESS, CONFIG.APPROVE_AMOUNT)
      .send({
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
      })
      .once("error", (err) => {
        console.log(err);
        swal("Sorry, Not Approved", "", "error");
      })
      .then(async (receipt) => {
        swal("Approved successfully", "", "success");
        let token = await blockchain.usdcContract.methods
          .allowance(blockchain.account, CONFIG.CONTRACT_ADDRESS)
          .call();
        setToken(token);
        setApprove(true);
        setMintFlag(true);
      });
  };

  const countPlus = () => {
    if (mintAmount < 50) {
      setMintAmount(mintAmount + 1);
    }
  };

  const countMinus = () => {
    if (mintAmount > 1) {
      setMintAmount(mintAmount - 1);
    }
  };

  const getData = () => {
    if (
      blockchain.account !== "" &&
      blockchain.smartContract !== null &&
      blockchain.usdcContract !== null
    ) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(async () => {
    if (
      blockchain.account !== "" &&
      blockchain.smartContract !== null &&
      blockchain.usdcContract !== null
    ) {
      let token = await blockchain.usdcContract.methods
        .allowance(blockchain.account, CONFIG.CONTRACT_ADDRESS)
        .call();
      setToken(token);
      if (token == 0) {
        setApprove(false);
        setMintFlag(false);
      } else {
        swal("Approved successfully", "", "success");
        setApprove(true);
        setMintFlag(true);
      }
    }
  }, [blockchain]);

  useEffect(() => {
    if (mintAmount > token) {
      setApprove(false);
      setMintFlag(false);
    } else {
      setApprove(true);
      setMintFlag(true);
    }
  }, [mintAmount]);

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  useEffect(() => {
    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", changeWidth);
    return () => {
      window.removeEventListener("resize", changeWidth);
    };
  }, []);

  const toggleNav = () => {
    setToggleMenu(!toggleMenu);
  };

  const conectWallet = () => {
    notification.warning({
      message: "Warning",
      description: "Please connect Metamask first.",
      placement: "topLeft",
    });
  };
  const submitToDiscord = () => {
    const webhookUrl = `https://discord.com/api/webhooks/${CONFIG.WEBHOOK_ID}/${CONFIG.WEBHOOK_TOKEN}`;
    if (email != "") {
      const messageContent = { title: "Email:", content: email };
      rp.post({
        url: webhookUrl,
        body: messageContent,
        json: true,
      })
        .then((data) => {
          notification.success({
            message: "Success",
            description: "You subscribed successfully",
            placement: "topLeft",
          });
          setEmail("");
        })
        .catch((err) => {
          notification.error({
            message: "error",
            description: "Connection error!",
            placement: "topLeft",
          });
        });
    }
  };

  return (
    <div id="hack" className="main-bg">
      <div>
        <nav>
          <a href="/">
            <img
              className="logo-image"
              src="./assets/images/logo/logo_new.png"
            />
          </a>
          {(toggleMenu || screenWidth > 1320) && (
            <ul className="list">
              <li className="items">
                <a className="nav-item" href="#">
                  Home
                </a>
              </li>
              <li className="nav-sub-item items dropdown">
                <div className="dropbtn">Docs</div>
                <div className="dropdown-content">
                  <a
                    className="sub-item"
                    href="https://minetopia.gitbook.io/docs/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    Gitbook
                  </a>
                </div>
              </li>
              <li className="items">
                <a className="nav-item" href="#team">
                  Team
                </a>
              </li>
              <li className="nav-sub-item items dropdown">
                <div className="dropbtn">Contact us</div>
                <div className="dropdown-content">
                  <a
                    className="sub-item"
                    href="https://discord.gg/5bF552m4zW"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    Discord
                  </a>
                  <a
                    className="sub-item"
                    href="https://twitter.com/MinetopiaNFT"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    Twitter
                  </a>
                </div>
              </li>
            </ul>
          )}
          <a onClick={toggleNav} className="menu-btn">
            <i className="fas fa-bars"></i>
          </a>
          <div className="row navbar_right">
            {blockchain.account == null && blockchain.account == undefined ? (
              <div
                className="wallet-opac"
                onClick={(e) => {
                  e.preventDefault();
                  dispatch(connect());
                  getData();
                }}
              >
                {/* {mintAmount >= 2 ? (
                  <button style={{ border: "none", background: "none" }}>
                    <img
                      className="wallet-button"
                      src="./assets/images/mint btn.png"
                    />
                  </button>
                ) : (
                  <></>
                )} */}
                {/* {mintAmount >= 2 ? (
                  <div className="wallet-button-text">CONNECT WALLET</div>
                ) : (
                  <></>
                )} */}
                {/* <button style={{ border: "none", background: "none" }}>
                  <img
                    className="wallet-button"
                    src="./assets/images/mint btn.png"
                  />
                </button> */}
                {/* <div className="wallet-button-text">CONNECT WALLET</div> */}
              </div>
            ) : (
              <div>
                <img
                  className="wallet-button"
                  src="./assets/images/mint btn.png"
                />
                <span className="wallet-button-text">
                  {String(blockchain.account).substring(0, 4) +
                    "..." +
                    String(blockchain.account).substring(38)}
                </span>
              </div>
            )}
          </div>

          <div className="navbar-right">
            <CrossmintPayButton
              className="connect-button crossmint btn"
              style={{ padding: "1px" }}
              collectionTitle="MineTopia"
              collectionDescription="Minetopia presents an opportunity for individuals to enter mining through the utility of Non-fungible Tokens (NFTs). 
              The potential mining options will include Bitcoin (BTC), Ethereum Classic (ETC), Kadena (KDA), and Litecoin (LTC) with free Dogecoin (DOGE). 
              As such, the project has found the blockchain to be a perfect form of distribution for mining rewards and achieving community-centric goals. 
              Minetopia is built with the expertise of well-versed marketers, developers, and designers who desire to work with a community to change the world for the betterment of earnings moving forward."
              collectionPhoto="https://ipfs.io/ipfs/QmVGNCPsiuEcPsZmY4jyH5F6cRsfxggsoSg7TgZ7W7myjA/"
              clientId="200e2469-b2c9-4b9b-8282-70cde11aad88"
              mintConfig={{
                type: "erc-721",
                totalPrice: "0.165",
              }}
            />
          </div>
        </nav>
        <section className="row d-flex first-section">
          <div
            className="col-lg-6 col-md-12 col-sm-12 align-content-center wow slideInLeft first-board"
            data-wow-offset="0"
          >
            <div>
              <div className="first-section-title-1 wow fadeInLeft">
                WELCOME TO
              </div>
              <div className="first-section-title-2 wow fadeInLeft">
                MINETOPIA!
              </div>
              <div className="first-subcontent wow fadeInLeft">
                Welcome to Minetopia, a revolutionary platform that provides
                individuals with the opportunity to participate in the mining
                industry through the use of Non-fungible Tokens (NFTs). We offer
                an extensive range of mining options, including Nervos Network
                (CKB), Kadena (KDA), Litecoin (LTC), Dogecoin (DOGE) as
                optional, Dash (DASH), and numerous other options to ensure that
                our users have access to a diverse range of possibilities.
              </div>
              <div className="row wow fadeInUp justifycontent">
                <div>
                  <p className="minting">Minting is</p>
                  <p className="live">Sold Out</p>
                </div>
                <div className="mint-count">
                  <div className="col-md-12 text-center count-section">
                    <button
                      onClick={countMinus}
                      className="count-btn count-minus"
                    >
                      -
                    </button>
                    <span className="mint-amount">{mintAmount}</span>
                    <button onClick={countPlus} className="count-btn">
                      +
                    </button>
                  </div>
                  {/* <div
                    className="mint-btn"
                    disabled={claimingNft ? 1 : 0}
                    onClick={(e) => {
                      e.preventDefault();
                      {
                        blockchain.account != null
                          ? claimNFTs()
                          : conectWallet();
                      }
                      getData();
                    }}
                  >
                    {mintAmount >= 2 ? (
                      <button style={{ border: "none", background: "none" }}>
                        <img src="./assets/images/mint btn.png" />
                      </button>
                    ) : (
                      <></>
                    )}
                    {mintAmount >= 2 ? (
                      <div className="mint-text">
                        {claimingNft ? "MINTING..." : "MINT NOW"}
                        {data.totalSupply > 0 ? (
                          <span>({data.totalSupply}/1000)</span>
                        ) : (
                          <span></span>
                        )}
                      </div>
                    ) : (
                      <></>
                    )}
                    <button style={{ border: "none", background: "none" }}>
                      <img src="./assets/images/mint btn.png" />
                    </button>
                    <div className="mint-text">
                      {claimingNft ? "MINTING..." : "MINT NOW"}
                      {data.totalSupply > 0 ? (
                        <span>({data.totalSupply}/1000)</span>
                      ) : (
                        <span></span>
                      )}
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-lg-6 col-md-12 col-sm-12 justify-content-center wow fadeInRight minetopia"
            data-wow-offset="0"
          >
            <img src="./assets/images/minetopia.png" />
          </div>
        </section>
      </div>
      <div>
        <section className="team-section" id="team">
          <div className="team-container">
            <div className="col-12 text-center d-flex justify-content-center wow fadeInUp">
              <div className="team_section_title line-height-15">THE TEAM</div>
            </div>
            <div className="row mx-0 my-5 team-block">
              <div
                className="col-lg-4 col-md-6 col-sm-12 text-center wow zoomInUp my-3"
                data-wow-offset="0"
              >
                <img className="team-image" src="./assets/images/kenny.png" />
                <div className="team-caption">WΞSTIE</div>
                <div className="team-desc">
                  <div className="my-2 color-white">CEO</div>
                  <a
                    className="color-white"
                    target="_blank"
                    href="https://twitter.com/Westieio"
                  >
                    Twitter{" "}
                    <span>
                      <u>@Westieio</u>
                    </span>
                  </a>
                </div>
              </div>
              <div
                className="col-lg-4 col-md-6 col-sm-12 text-center wow zoomInUp my-3"
                data-wow-offset="0"
              >
                <img className="team-image" src="./assets/images/dxniel.png" />
                <div className="team-caption">CHRISTINΞ </div>
                <div className="team-desc">
                  <div className="my-2 color-white">CMO</div>
                  <a
                    className="color-white"
                    target="_blank"
                    href="https://twitter.com/OnlyEyes4Crypto"
                  >
                    Twitter{" "}
                    <span>
                      <u>@OnlyEyes4Crypto</u>
                    </span>
                  </a>
                </div>
              </div>
              <div
                className="col-lg-4 col-md-6 col-sm-12 text-center wow zoomInUp my-3"
                data-wow-offset="0"
              >
                <img className="team-image" src="./assets/images/DNIL.png" />
                <div className="team-caption">DXNIEL</div>
                <div className="team-desc">
                  <div className="my-2 color-white">CCO</div>
                  <a
                    className="color-white"
                    target="_blank"
                    href="https://twitter.com/DxnielETH"
                  >
                    Twitter{" "}
                    <span>
                      <u>@DxnielETH</u>
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="discord-section">
          <div className="d-flex justify-content-center faq-container discord-joint wow fadeIn">
            <div className="col-6 joint-community">
              <span className="joint-community-title">Join Our Community</span>
              <div className="joint-community-description">
                <p>
                  Do you want to be part of our DAO members? Join our Discord to
                  find out more and we hope to see you soon!{" "}
                </p>
              </div>
              <div
                className="row d-flex justify-content-center row-left"
                style={{ marginLeft: "0px !important" }}
              >
                <div
                  onClick={() =>
                    window.open("https://twitter.com/MinetopiaNFT")
                  }
                  className="row twitter-opac mt-1 mr-1"
                  style={{ cursor: "pointer" }}
                >
                  <button className="twitter-btn">
                    <img src="./assets/images/mint btn.png"></img>
                  </button>
                  <i className="fab fa-twitter twitter-icon"></i>
                  <span className="joint-discord-text">Twitter</span>
                </div>
                <div
                  onClick={() => window.open("https://discord.gg/5bF552m4zW")}
                  className="row discord-opac mt-1"
                  style={{ cursor: "pointer" }}
                >
                  <button className="discord-btn">
                    <img src="./assets/images/mint btn.png"></img>
                  </button>
                  <i className="fab fa-discord discord-icon"></i>
                  <span className="joint-discord-text">Join Discord</span>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="footer-section">
          <div className="text-center">
            <img className="sub-trans" src="./assets/images/sub_trans.png" />
          </div>
          <div className="copyright-section text-center">
            Copyright <i className="far fa-copyright"></i> 2022 All Rights
            Reserved by Minetopia.
          </div>
          <div className="text-center my-3">
            <Link className="sub-item mr-3" to="/privacy" target="_blank">
              {" "}
              Minetopia Privacy
            </Link>
            <Link
              className="sub-item"
              to="/terms-and-conditions"
              target="_blank"
            >
              {" "}
              Minetopia T&C
            </Link>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Home;
