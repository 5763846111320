"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.crossmintModalService = void 0;
var ui_1 = require("../utils/ui");
var overlayId = "__crossmint-overlay__";
var getChromeVersion = function () {
    var raw = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);
    return raw ? parseInt(raw[2]) : null;
};
function createPopupString() {
    var left = window.innerWidth / 2 - 200;
    var top = window.innerHeight / 2 - 375;
    // In newer versions of chrome (>99) you need to add the `popup=true` for the new window to actually open in a popup
    var chromeVersion = getChromeVersion();
    var chromeVersionGreaterThan99 = chromeVersion && chromeVersion > 99;
    var popupStringBase = chromeVersionGreaterThan99 ? "popup=true," : "";
    return "".concat(popupStringBase, "height=750,width=400,left=").concat(left, ",top=").concat(top, ",resizable=yes,scrollbars=yes,toolbar=yes,menubar=true,location=no,directories=no, status=yes");
}
var addLoadingOverlay = function (dissmissableOverlayOnClick) {
    var overlayEl = document.createElement("div");
    overlayEl.setAttribute("id", overlayId);
    var overlayStyles = {
        width: "100vw",
        height: "100vh",
        "background-color": "rgba(0, 0, 0, 0.5)",
        position: "fixed",
        "z-index": "99999999",
        top: "0",
        left: "0",
    };
    Object.assign(overlayEl.style, overlayStyles);
    document.body.appendChild(overlayEl);
    if (dissmissableOverlayOnClick) {
        overlayEl.addEventListener("click", function () {
            removeLoadingOverlay();
        });
    }
};
var removeLoadingOverlay = function () {
    var overlayEl = document.getElementById(overlayId);
    if (overlayEl)
        overlayEl.remove();
};
function crossmintModalService(_a) {
    var clientId = _a.clientId, libVersion = _a.libVersion, showOverlay = _a.showOverlay, dismissOverlayOnClick = _a.dismissOverlayOnClick, setConnecting = _a.setConnecting, environment = _a.environment, clientName = _a.clientName;
    var createPopup = function (mintConfig, collectionTitle, collectionDescription, collectionPhoto, mintTo, emailTo, listingId, whPassThroughArgs, paymentMethod, preferredSigninMethod) {
        var urlOrigin = (0, ui_1.getEnvironmentBaseUrl)(environment);
        var getMintQueryParams = function () {
            var mintQueryParams = {
                clientId: clientId,
                closeOnSuccess: "false",
                clientName: clientName,
                clientVersion: libVersion,
                mintConfig: JSON.stringify(mintConfig),
            };
            if (collectionTitle)
                mintQueryParams.collectionTitle = collectionTitle;
            if (collectionDescription)
                mintQueryParams.collectionDescription = collectionDescription;
            if (collectionPhoto)
                mintQueryParams.collectionPhoto = collectionPhoto;
            if (mintTo)
                mintQueryParams.mintTo = mintTo;
            if (emailTo)
                mintQueryParams.emailTo = emailTo;
            if (listingId)
                mintQueryParams.listingId = listingId;
            if (whPassThroughArgs)
                mintQueryParams.whPassThroughArgs = JSON.stringify(whPassThroughArgs);
            if (paymentMethod)
                mintQueryParams.paymentMethod = paymentMethod;
            if (preferredSigninMethod)
                mintQueryParams.preferredSigninMethod = preferredSigninMethod;
            return new URLSearchParams(mintQueryParams).toString();
        };
        // var callbackUrl = encodeURIComponent("".concat(urlOrigin, "/checkout/mint?").concat(getMintQueryParams()));
        // var url = "".concat(urlOrigin, "/signin?callbackUrl=").concat(callbackUrl);
        var pop = window.open(urlOrigin, "popUpWindow", createPopupString());
        if (pop) {
            registerListeners(pop);
            if (showOverlay) {
                addLoadingOverlay(dismissOverlayOnClick);
            }
            return;
        }
        setConnecting(false);
        var newTab = window.open(url, "_blank");
        if (!newTab) {
            console.error("Failed to open popup window and new tab");
        }
    };
    var connect = function (mintConfig, collectionTitle, collectionDescription, collectionPhoto, mintTo, emailTo, listingId, whPassThroughArgs, paymentMethod, preferredSigninMethod) {
        setConnecting(true);
        createPopup(mintConfig, collectionTitle, collectionDescription, collectionPhoto, mintTo, emailTo, listingId, whPassThroughArgs, paymentMethod, preferredSigninMethod);
    };
    function registerListeners(pop) {
        var timer = setInterval(function () {
            if (pop.closed) {
                clearInterval(timer);
                setConnecting(false);
                if (showOverlay) {
                    removeLoadingOverlay();
                }
            }
        }, 500);
    }
    return {
        connect: connect,
    };
}
exports.crossmintModalService = crossmintModalService;
