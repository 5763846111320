"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.crossmintPayButtonService = void 0;
var types_1 = require("../models/types");
function crossmintPayButtonService(_a) {
    var onClick = _a.onClick, connecting = _a.connecting, paymentMethod = _a.paymentMethod;
    var checkProps = function (_a) {
        var collectionTitle = _a.collectionTitle, collectionDescription = _a.collectionDescription, collectionPhoto = _a.collectionPhoto;
        var _collectionTitle = collectionTitle;
        var _collectionDescription = collectionDescription;
        var _collectionPhoto = collectionPhoto;
        if (collectionTitle === "<TITLE_FOR_YOUR_COLLECTION>") {
            console.warn("No collection title specified. Please add a collection title to your <CrossmintPayButton />");
            _collectionTitle = "";
        }
        if (collectionDescription === "<DESCRIPTION_OF_YOUR_COLLECTION>") {
            console.warn("No collection description specified. Please add a collection description to your <CrossmintPayButton />");
            _collectionDescription = "";
        }
        if (collectionPhoto === "<OPT_URL_TO_PHOTO_COVER>") {
            console.warn("No collection photo specified. Please add a collection photo to your <CrossmintPayButton />");
            _collectionPhoto = "";
        }
        return [_collectionTitle, _collectionDescription, _collectionPhoto];
    };
    var getButtonText = function (connecting) {
        if (connecting) {
            return "Connecting...";
        }
        switch (paymentMethod) {
            case "ETH":
                return "Buy with ETH";
            case "SOL":
                return "Buy with SOL";
            default:
                return "Buy with credit card";
        }
    };
    var shouldHideButton = function (_a) {
        var hideMintOnInactiveClient = _a.hideMintOnInactiveClient, status = _a.status;
        return hideMintOnInactiveClient && status !== types_1.onboardingRequestStatusResponse.ACCEPTED;
    };
    var handleClick = function (event, cb) {
        if (onClick)
            onClick(event);
        if (connecting)
            return;
        if (!event.defaultPrevented) {
            cb();
        }
    };
    return {
        checkProps: checkProps,
        getButtonText: getButtonText,
        shouldHideButton: shouldHideButton,
        handleClick: handleClick,
    };
}
exports.crossmintPayButtonService = crossmintPayButtonService;
