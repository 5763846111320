import { createUseStyles } from "react-jss";

const DARK_BG = "#1e1e1e";

const themeIsLight = (theme) => theme === "light";
export const formatProps = (theme) => ({
    buttonBgColor: themeIsLight(theme) ? "white" : DARK_BG,
    paragraphColor: themeIsLight(theme) ? "white" : "white",
});

export const useStyles = createUseStyles({
    "@global": {
        "@import":
            "url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap')",
    },
    crossmintButton: {
        display: "flex",
        "flex-direction": "row",
        "align-items": "center",
        "font-weight": "400",
        transition: "opacity ease-in-out 0.25s",
        "font-family": `"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif`,
        outline: "none",
        border: "none",
        "box-shadow": "0px 8px 15px rgba(0, 0, 0, 0.1)",
        "justify-content": "center",
        background: ({ buttonBgColor }) => buttonBgColor,

        "&:hover:enabled": {
            opacity: "0.6",
            cursor: "pointer",
        },
    },
    crossmintImg: {
        "max-width": "20px",
        "max-height": "21px",
        "margin-left": "0.8rem"
        // "margin-right": "0.875rem",
    },
    crossmintParagraph: {
        fontFamily: "azonixfont",
        "margin-left": "10px",
        "margin-right": "25px",
        color: ({ paragraphColor }) => paragraphColor,
        margin: "0",
    },
});
