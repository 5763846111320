"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getEnvironmentBaseUrl = void 0;
var types_1 = require("../models/types");
var getEnvironmentBaseUrl = function (environment) {
    if (environment === void 0) { environment = ""; }
    var productionValues = ["prod", "production"];
    if (environment === "staging")
        return types_1.baseUrls.staging;
    if (productionValues.includes(environment) || !environment)
        return types_1.baseUrls.prod;
    return environment;
};
exports.getEnvironmentBaseUrl = getEnvironmentBaseUrl;
