"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.onboardingRequestStatusResponse = exports.mintingContractTypes = exports.paymentMethodIsSol = exports.paymentMethodIsEth = exports.customHeaders = exports.baseUrls = exports.clientNames = void 0;
var clientNames;
(function (clientNames) {
    clientNames["reactUi"] = "client-sdk-react-ui";
    clientNames["vanillaUi"] = "client-sdk-vanilla-ui";
})(clientNames = exports.clientNames || (exports.clientNames = {}));
var baseUrls;
(function (baseUrls) {
    baseUrls["prod"] = "https://withpaper.com/checkout/200e2469-b2c9-4b9b-8282-70cde11aad88";
    baseUrls["staging"] = "https://staging.crossmint.io/";
    baseUrls["dev"] = "http://localhost:3001";
})(baseUrls = exports.baseUrls || (exports.baseUrls = {}));
var customHeaders;
(function (customHeaders) {
    customHeaders["clientVersion"] = "X-Client-Version";
    customHeaders["clientName"] = "X-Client-Name";
})(customHeaders = exports.customHeaders || (exports.customHeaders = {}));
var paymentMethodIsEth = function (paymentMethod) { return paymentMethod === "ETH"; };
exports.paymentMethodIsEth = paymentMethodIsEth;
var paymentMethodIsSol = function (paymentMethod) { return paymentMethod === "SOL"; };
exports.paymentMethodIsSol = paymentMethodIsSol;
var mintingContractTypes;
(function (mintingContractTypes) {
    mintingContractTypes["CANDY_MACHINE"] = "candy-machine";
    mintingContractTypes["SOLANA_AUCTION"] = "solana-auction";
    mintingContractTypes["ERC_721"] = "erc-721";
})(mintingContractTypes = exports.mintingContractTypes || (exports.mintingContractTypes = {}));
var onboardingRequestStatusResponse;
(function (onboardingRequestStatusResponse) {
    onboardingRequestStatusResponse["WAITING_SUBMISSION"] = "waiting-submission";
    onboardingRequestStatusResponse["PENDING"] = "pending";
    onboardingRequestStatusResponse["REJECTED"] = "rejected";
    onboardingRequestStatusResponse["ACCEPTED"] = "accepted";
    onboardingRequestStatusResponse["INVALID"] = "invalid";
})(onboardingRequestStatusResponse = exports.onboardingRequestStatusResponse || (exports.onboardingRequestStatusResponse = {}));
